import { request } from '@/utils/request'

/** 通用接口 */
const globalApi = {

  /** 获取枚举类型 */
  getReference: () => request.get('global/reference'),

  /** 获取选择器候选项 */
  getSelectorOptions: params => request.get('selector', params),

  // 获取省市区列表以及根据下属
  getAreaData: (params = {}) => request.get('/Area/area/getAreaData', params),

  /** 上传接口 */
  globalUpLoad: (data:any) => request.upload('global/upload', data, { disableThrottling: true }),

}

export { globalApi }

