

<template>
  <div>
    <div>{{ value }}</div>
    <div>{{ dynamicModel }}</div>
    <div class=" ">
      <a-space
        v-for="(item, Tindex) in dynamicSchemas"
        :key="Tindex"
        class=" flex mt-3"
      >
        <form-item
          v-for="schema in item"
          :key="schema.field"
          v-model:dynamicValue="dynamicModel[Tindex][schema.field]"
          :form-props="schema.formProps"
          :dynamic-rules="[props.validKey,Tindex,schema.field]"
          :is-dynamic="true"
          :valid-key="props.validKey"
          :dynamic-index="Tindex"
          :schema="schema"
        /> <MinusCircleOutlined
          v-if="Tindex===dynamicSchemas.length-1"
          class="ml-2"
          @click="remove()"
        />
      </a-space>
    </div>
    <a-form-item class="mt-2">
      <a-button
        type="dashed"
        block
        @click="add"
      >
        <PlusOutlined />
        添加项
      </a-button>
    </a-form-item>
  </div>
</template>
<script lang='ts' setup>
import { cloneDeep } from 'lodash-es'
import { dynamicFormProps } from './props'
const props = defineProps(dynamicFormProps)

// const dynamicModel = ref<Recordable[]>(props.value || [])
const dynamicModel = computed(() => {
  if (props.value) {
    return props.value
  } else {
    let temp = {}
    props.dynamicValidateForm.forEach(item => {
      temp[item.field] = undefined
    })
    return [temp]
  }
})
const addModel = () => {
  let temp = {}
  props.dynamicValidateForm.forEach(item => {
    temp[item.field] = undefined
  })
  dynamicModel.value.push(temp)
}
const dynamicSchemas = computed(() => dynamicModel.value.map(i => (cloneDeep(props.dynamicValidateForm))))
const emits = defineEmits(['update:value'])
emits('update:value', dynamicModel.value)
const add = () => {
  dynamicSchemas.value.push(cloneDeep(props.dynamicValidateForm))
  addModel()
}
const remove = () => {
  dynamicModel.value.pop()
  dynamicSchemas.value.pop()
}
</script>
