import { request } from '@/utils/request'


/** 资金管理 */
const fundManagementApi = {

  /** 余额明细 */
  balanceDetail: params => request.get('/Payment/PaymentLogs/index', params),

  /** 发薪账户 */
  getWageAccount: () => request.get('Payment/PaymentAccounts/index'),
}

/** 发新单接口 */
const wageApi = {

  /** 获取发薪列表 */
  getWageRecordList: params => request.get('Salary/StaffUserSalaryPays/index', params),


  /** 发薪详情 */
  getWageRecordDetail: id => request.get('Salary/StaffUserSalaryPays/show', { id }),

  /** 发薪导入检测 */
  wageImportChecked: params => request.post('Salary/StaffUserSalaryPays/importStaffUserCheck', params),

  /** 补发检测 */
  reissueChecked: params => request.post('Salary/StaffUserSalaryPays/importReissueStaffUserCheck', params),

  /** 发薪成员列表 */
  getWageRecordDetailList: params => request.get('Salary/StaffUserSalaryPayDetails/index', params),

  /** 支付账户 */
  getPayAccount: () => request.get('Payment/PaymentAccounts/selector'),

  /** 导入发薪 */
  importWage: params => request.post('Salary/StaffUserSalaryPays/store', params),

  /** 发薪明细列表 */
  getWageDetailList: params => request.get('Salary/StaffUserSalaryPayDetails/index', params)
}

// 服务费管理接口
const serviceChargeApi = {
  getServiceChargeList: params => request.get('/Salary/ServiceChargeOrders/index', params),
  // 支付
  payServiceCharege: params => request.post('/Salary/ServiceChargeOrders/payOrder', params),
  // 获取详情
  getDetialServiceCharge: id => request.get('/Salary/ServiceChargeOrders/show', { id }),

}

// 服务费账单页面的
const invoice2Api = {
  // 申请开票
  storeInvoice: params => request.post('/invoice/invoices/store', params),
  // 开票记录
  invoiceList: params => request.post('/invoice/invoices/index', params),
  // 开票详情
  invoiceDetail: id => request.get('/invoice/invoices/show', { id }),
  // 快递公司列表
  expressCompanyList: () => request.get('/invoice/invoices/expressCompany', {}),
  // 确认收到
  confirmReceiveInvoice: params => request.post('/invoice/invoices/receiveInvoice', params),
}

/** 发票模块 */
const invoiceApi = {

  /** 列表 */
  getInvoiceList: params => request.get('/invoice/invoices/index', params),

  // 详情
  getInvoiceDetail: params => request.get('/invoice/invoices/show', params),

  // 申请开票，新增/修改
  newAndUpdateInvoice: params => request.post('/invoice/invoices/store', params),

  // 获取可开票的发薪记录
  getCanInvoiceSalaryPays: params => request.get('/Salary/StaffUserSalaryPays/getCanInvoiceSalaryPays', params)
}

/** 寄送地址管理 */
const sendAddrManagementApi = {
  // 列表
  getAddrList: params => request.get('/invoice/invoiceSendAddresses/index', params),
  // 新增/更新
  newAndUpdate: params => request.post('/invoice/invoiceSendAddresses/store', params),
  // 删除
  delAddr: params => request.post('/invoice/invoiceSendAddresses/delete', params),

  // 下拉框
  invoiceSendAddrSelect: params => request.get('/invoice/invoiceSendAddresses/selector', params),

  // 获取默认地址
  defaultInvoiceSendAddr: () => request.get('/invoice/invoiceSendAddresses/defaultInvoiceSendAddress')
}

export { wageApi, invoiceApi, sendAddrManagementApi, fundManagementApi, serviceChargeApi, invoice2Api }