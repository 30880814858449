import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'

const workerChildrenRoutes: CustomRouteRecordRaw[] = [
  // 员工花名册
  {
    path: 'worker-list',
    name: 'workerList',
    component: () => import('@/views/worker/worker-list.vue'),
    meta: {
      title: '员工花名册',
      iconfont: 'icon-contacts-book-line',
      requiredPermission: 'WORKER_LIST',
    },
  },
  // 薪资列表
  {
    path: 'salary-list',
    name: 'salaryList',
    component: () => import('@/views/worker/salary-list.vue'),
    meta: {
      title: '薪资名单',
      iconfont: 'icon-database-2-line',
      requiredPermission: 'SALARY_LIST',
    },
  },

  // 创建/编辑员工
  {
    path: 'worker-create',
    name: 'workerCreate',
    component: () => import('@/views/worker/worker-create.vue'),
    meta: {
      hideInMenu: true,
      // actionRouterName: 'workerList',
      parentNameForBreadcrumb: 'workerList',
      title: '新增员工',
      keepAlive: true
    },
  },
  {
    path: 'worker-edit/:workerId/:projectId',
    name: 'workerEdit',
    component: () => import('@/views/worker/worker-edit.vue'),
    meta: {
      hideInMenu: true,
      // actionRouterName: 'workerList',
      parentNameForBreadcrumb: 'workerList',
      title: '编辑员工',
      keepAlive: true
    },
  },
  // 员工详情
  {
    path: 'worker-detail/:id',
    name: 'workerDetail',
    component: () => import('@/views/worker/worker-detail.vue'),
    meta: {
      title: '员工详情',
      hideInMenu: true,
      keepAlive: true,
      // actionRouterName: 'workerList',
      parentNameForBreadcrumb: 'workerList'
      // requiredPermission: 'ADMIN_VIEW',
    },
  },
  // 新增员工(零工项目)
  {
    path: 'odd-job-worker-create',
    name: 'oddJobWorkerCreate',
    component: () => import('@/views/worker/odd-job-worker-create.vue'),
    meta: {
      hideInMenu: true,
      parentNameForBreadcrumb: 'workerList',
      title: '新增员工',
      keepAlive: true
    },
  },
  // 编辑员工(零工项目)
  {
    path: 'odd-job-worker-edit/:workerId',
    name: 'oddJobWorkerEdit',
    component: () => import('@/views/worker/odd-job-worker-edit.vue'),
    meta: {
      hideInMenu: true,
      parentNameForBreadcrumb: 'workerList',
      title: '编辑员工',
      keepAlive: true
    },
  },
  // 员工详情(零工项目)
  {
    path: 'odd-job-worker-detail/:id',
    name: 'oddJobWorkerDetail',
    component: () => import('@/views/worker/odd-job-worker-detail.vue'),
    meta: {
      title: '员工详情',
      hideInMenu: true,
      keepAlive: true,
      parentNameForBreadcrumb: 'workerList'
    },
  },
  {
    path: 'social-increase',
    name: 'socialIncrease',
    component: () => import('@/views/worker/social-increase.vue'),
    meta: {
      title: '社保增员名单',
      iconfont: 'icon-bar-chart-2-line',
      requiredPermission: 'SOCIAL_INCREASE',
    }
  },
  {
    path: 'social-reduce',
    name: 'socialReduce',
    component: () => import('@/views/worker/social-reduce.vue'),
    meta: {
      title: '社保减员名单',
      iconfont: 'icon-bar-chart-box-line',
      requiredPermission: 'SOCIAL_REDUCE',
    }
  },
  {
    path: 'additional-deduction',
    name: 'additionalDeduction',
    component: () => import('@/views/worker/additional-deduction.vue'),
    meta: {
      title: '累计专项附加扣除',
      iconfont: 'icon-currency_exchange_black_24dp',
      requiredPermission: 'ADDITIONAL_DEDUCTION',
    },
  },
  {
    path: 'contract-list',
    name: 'contractList',
    component: () => import('@/views/worker/contract/contract-list.vue'),
    meta: {
      title: '合同签署列表',
      iconfont: 'icon-todo-line',
      requiredPermission: 'CONTRACT_LIST',
      showBadge: 'unsignedContractCount',
    }
  },
  {
    path: 'contract-template-list',
    name: 'contractTemplateList',
    component: () => import('@/views/worker/contract/contract-template-list.vue'),
    meta: {
      title: '电子合同模板',
      iconfont: 'icon-file-list-3-line',
      requiredPermission: 'CONTRACT_TEMPLATE_LIST',
      keepAlive: true,
    }
  },
  {
    path: 'contract-template-detail/:templateId',
    name: 'contractTemplateDetail',
    component: () => import('@/views/worker/contract/contract-template-detail.vue'),
    meta: {
      title: '发起签署',
      hideInMenu: true,
      keepAlive: true,
      // actionRouterName: 'contractTemplateList',
      parentNameForBreadcrumb: 'contractTemplateList'
    }
  },
  {
    path: 'worker-to-be-employed',
    name: 'workerToBeEmployed',
    component: () => import('@/views/worker/worker-to-be-employed.vue'),
    meta: {
      title: '待办理入职员工',
      iconfont: 'icon-user-add-line',
      requiredPermission: 'WORKER_TO_BE_EMPLOYED',
      showBadge: 'toBeEmployedUsersCount',
    }
  },
  {
    path: 'worker-to-be-confirmed',
    name: 'workerToBeConfirmed',
    component: () => import('@/views/worker/worker-to-be-confirmed.vue'),
    meta: {
      title: '待转正员工列表',
      iconfont: 'icon-user-2-line',
      requiredPermission: 'WORKER_TO_BE_CONFIRMED',
      showBadge: 'toBeConfirmedUsersCount',
    }
  },
  {
    path: 'contract-to-be-expired',
    name: 'contractToBeExpired',
    component: () => import('@/views/worker/contract-to-be-expired.vue'),
    meta: {
      title: '合同即将到期列表',
      iconfont: 'icon-user-2-line',
      requiredPermission: 'CONTRACT_TO_BE_EXPIRED',
      showBadge: 'contractToBeExpiredCount',
    }
  },
  {
    path: 'idcard-to-be-expired',
    name: 'idcardToBeExpired',
    component: () => import('@/views/project/idcard-to-be-expired.vue'),
    meta: {
      title: '证件即将到期列表',
      iconfont: 'icon-user-2-line',
      showBadge: 'idcardToBeExpiredCount',
      requiredPermission: 'IDCARD_TO_BE_EXPIRED',
    }
  },
  {
    path: 'idcard-export',
    name: 'idcardExport',
    component: () => import('@/views/project/idcard-export.vue'),
    meta: {
      title: '导出证件数据',
      iconfont: 'icon-todo-line',
      requiredPermission: 'IDCARD_EXPORT',
    }
  },
  {
    path: 'black-list',
    name: 'blackList',
    component: () => import('@/views/worker/black-list.vue'),
    meta: {
      title: '黑名单列表',
      iconfont: 'icon-contacts-book-line',
      requiredPermission: 'BLACK_LIST',
    },
  },

]
export const workerRoutes: CustomRouteRecordRaw = {
  path: 'worker',
  name: 'worker',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(workerChildrenRoutes),
  meta: {
    title: '人事管理',
    iconfont: 'icon-user-plus',
    desc: '以员工为维度内容',
    requiredPermission: 'WORKER',
    noQuick: true
  },
  children: workerChildrenRoutes
}