<style lang="less" scoped>

</style>
<template>
  <div>
    <a-form-item-rest>
      <template v-if="dataSource.length === 0">
        <div
          v-if="props.textKeyMapArray.length > 0"
          class="w-full mb-2 text-center text-red-500 text-l"
        >
          **多sheet导入暂不支持校验数据和预览，请自行核对**
        </div>
        <!-- 设置弹性布局，无法使上传组件宽度100% -->
        <!-- <div class="flex justify-center"> -->
        <div class="w-full">
          <a-upload-dragger
            :file-list="fileList "
            :max-count="1"
            v-bind="bindAttrs"
            :multiple="false"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <basic-icon
                name="icon-upload_black_24dp"
                class="text-4xl"
              />
            </p>
            <p class="ant-upload-text">
              点击或拖拽文件到此上传
            </p>
            <p class="px-2 ant-upload-hint">
              可直接将文件拖拽到此处进行上传，支持格式: XLS、XLSX，大小不超过5M
            </p>
          </a-upload-dragger>
        </div>
        <Divider class="mt-10" />
        <div class="flex justify-between mb-2 text-red-500 ">
          上传前请确认： <span
            v-if="Boolean(props.downLoadUrl)"
            class="text-blue-500 cursor-pointer"
            @click="downloadFile(props.downLoadUrl, {}, props.excelName, 'xlsx')"
          >
            下载模板
          </span>
        </div>
        <div
          v-for="(tip, index) in props.warningTexts"
          :key="index"
          :class="tip.class"
          class="mb-1 text-xs "
        >
          {{ tip.text }}
        </div>
      </template>

      <!-- 已上传文件，则预览数据 -->
      <div v-if="dataSource.length > 0">
        <a-button
          v-if="unValidateNumber.length > 0"
          type="primary"
          class="float-right ml-2 "
          style="margin-top: 2px;"
          @click="downloadError(errorList)"
        >
          下载错误名单
        </a-button>
        <a-button
          type="primary"
          class="float-right "
          style="margin-top: 2px;"
          @click="toggle"
        >
          全屏预览
        </a-button>
        <div class="flex justify-between mt-5 mb-4">
          <div>
            检测结果：共导入{{ dataSource.length }}条，<span class="text-green-500">{{ dataSource.length - unValidateNumber.length
            }}</span>条正常，<span class="text-red-500">{{ unValidateNumber.length }}</span>条异常
          </div>
        </div>
        <a-table
          ref="tableRef"
          :bordered="true"
          :scroll="{
            x: isFullscreen ? 'calc(100vh - 55px)' : tableWidth,
          }"
          :data-source="dataSource"
          :columns="columns"
          :pagination="{
            total: dataSource.length,
            pageSize: size,
            showQuickJumper: false,
            showLessItems: true,
            onShowSizeChange: onShowSizeChange,
            showSizeChanger: true
          }"
        >
          <template #bodyCell="{ column, record }">
            <span>
              <div
                v-if="errorMsgReg.test(record[column.dataIndex])"
                class="text-red-500"
              >
                {{ record[column.dataIndex].substring(record[column.dataIndex].search(errorMsgReg),) }}
              </div>
              <div> {{ record[column.dataIndex]?.replace(errorMsgReg, '') }}</div>
            </span>
          </template>
        </a-table>
      </div>
    </a-form-item-rest>
  </div>
</template>
<script lang="tsx" setup>
import { useMessage } from '@/hooks/message'
import { downloadFile } from '@/utils/file'
import { exportExcelDetail, getExcelOriginCell, getSheetHeaderAndData } from '@/utils/xlsx'
import { TableColumnsType, Divider } from 'ant-design-vue'
import { excelImportProps } from './props'
import { cloneDeep, isEmpty, omit, pick } from 'lodash-es'
import { Table } from 'ant-design-vue/es'
import { getRandomName } from './common/random-text-key'
import { catchData } from './common/catchData'
import { IExcelInfo } from './excel'
import { useEnv } from '@/hooks/env'
import { useUserStore } from '@/store'
import { dataFilter, formatDate, removePercentSign, validateData } from './common/utils'
import { formatToDate } from '@/utils/date'
type UpdateParams = IExcelInfo['resultList'] | {list:IExcelInfo['resultList'], filePaths: Array<string> | string}
type EmitEvents = {
  (e: 'update:value', params:UpdateParams): void,
  (e:'resolveComplete', columns:TableColumnsType)
}
const excelInfo = ref<IExcelInfo>({
  resultList: [], // 最后需要传递给form的数据
})

// 文件路径数组
const filePaths = ref<string[]>([])

/** 校验携带错误信息的正则 包含 * * * 错误信息 * * * 则说明校验不通过*/
const errorMsgReg = /\*\*\*(.*?)\*\*\*/
const fileList = ref([])
const dataSource = ref<Recordable[]>([])
let errorList = ref<Recordable[]>([])
const columns = ref<TableColumnsType>([])
const unValidateNumber = ref<number[]>([])
const tableRef = ref<InstanceType<typeof Table>>()
const tableWidth = ref(0)
const size = ref(5)
const emits = defineEmits<EmitEvents>()
const props = defineProps(excelImportProps)
const { toggle, isFullscreen } = useFullscreen(tableRef)
const attrs = useAttrs()
const onShowSizeChange = (current: number, pageSize: number) => {
  size.value = pageSize
}
const downloadError = async dataList => {
  let textKeyMaps = Object.keys(props.textKeyMap).map(key => ({ [key]: props.textKeyMap[key] }))
  await exportExcelDetail({
    textKeyMaps,
    dataList,
    excelName: `${props.excelName}信息有误数据表`
  })
}
// 获取上传接口调用的请求头
const getHeaders = computed(() => ({
  ...pick(attrs, 'headers'),
  Authorization: `Bearer ${useUserStore().token}`,
}))
const getParams = computed(() => ({
  ...pick(attrs, 'data'),
  type: '.xlsx',
}))
const bindAttrs = computed(() => {
  let tempAttrs = {} as Recordable
  if (props.isUpload) {
    tempAttrs = {
      beforeUpload: file => file,
      action: useEnv.uploadApiUrl,
      headers: unref(getHeaders),
      data: unref(getParams),
      accept: '.xlsx',
      ...omit(attrs, ['headers', 'data']),
    }
  } else {
    tempAttrs.beforeUpload = () => false
  }
  return tempAttrs
})


// 重置表格数据
const resetTable = () => {
  fileList.value = []
  dataSource.value = []
  excelInfo.value.resultList = []
  emits('update:value', unref(excelInfo).resultList)
}


async function handleChange({ file, fileList: _fileList }) {
  fileList.value = _fileList // 设置为受控组件，需要将 uploading 状态的 file 同步到 files 中，使的上传功能继续执行，才能触发 done 等上传结束事件
  unValidateNumber.value = []
  if (file.status !== 'done' && file.status !== 'removed' && props.isUpload) {
    return
  }


  if (file.status === 'removed') {
    resetTable()
    return
  }
  filePaths.value = toRaw(_fileList)
    .filter(f => f.status === 'done')
    .map(f => {
      if (f.response) { // 新上传的文件
        return f.response.data.path
      } else { // 数据回填的文件
        return new URL(f.url!).pathname
      }
    })


  const { excelData: sheetList, sheetNames } = await getExcelOriginCell(props.isUpload ? file.originFileObj : file) // 获取 excel 表所有 sheet 所有单元格数据

  // 多sheet导入时计算所有sheet的数据总数，单sheet导入只计算第一个sheet的数据长度
  if (props.maxImportNum < (props.textKeyMapArray.length > 0 ? sheetList.reduce((sum, sheet) => sum + sheet.length, 0) : sheetList[0].length)) {
    useMessage.error(`最多导入${props.maxImportNum}条（包含表头）`)
    resetTable()
    return
  }

  if (props.textKeyMapArray.length > 0) {
    const { excelList, status } = multipleSheets(sheetList, sheetNames)
    if (!status) {
      useMessage.error('模板错误，请选择正确模板')
      return
    }
    excelInfo.value.resultList = excelList
  } else {
    const { headerColumns, unValiDateDataList, errorAndOriginalDataList, valiDateDataList, status } = singleSheet(sheetList)
    if (!status) {
      useMessage.error('模板错误，请选择正确模板')
      return
    }
    console.log('headerColumns', headerColumns) // table 表头结构
    console.log('errorAndOriginalDataList', errorAndOriginalDataList) // table 表格数据
    console.log('valiDateDataList', valiDateDataList) // 传递给后端的数据
    tableWidth.value = headerColumns.length * 110
    columns.value = headerColumns
    dataSource.value = errorAndOriginalDataList
    errorList.value = unValiDateDataList
    excelInfo.value.resultList = valiDateDataList
  }
  catchData(excelInfo.value.resultList, () => {
    const emitsValue:UpdateParams = props.isUpload ? {
      list: unref(excelInfo).resultList,
      filePaths: toRaw(_fileList)
        .filter(f => f.status === 'done')
        .map(f => {
          if (f.response) { // 新上传的文件
            return f.response.data.path
          } else { // 数据回填的文件
            return new URL(f.url!).pathname
          }
        })
    } : unref(excelInfo).resultList
    emits('update:value', emitsValue)
    emits('resolveComplete', columns.value)
  })
}
// 单个sheet导入
function singleSheet(sheetList) {
  // 删除行 目前只做了单sheet导入支持
  sheetList[0].splice(0, props.removeRow)
  let { headerColumns, dataList, dataSourceList } = getSheetHeaderAndData(sheetList[0], props.textKeyMap)
  const headerColumnsTextKey = headerColumns.map(i => i.title)
  let textKey = Object.keys(props.textKeyMap)

  // 根据表头生成textKey
  if (props.notTextKeyMap) {
    textKey = headerColumnsTextKey
    headerColumns.forEach(column => {
      if (!column.dataIndex) {
        column.dataIndex = column.title
      }
    })
  }

  /* 处理除配置以外的表头数据 生成随机的字段用于展示*/
  const excessTextKey = getRandomName(headerColumnsTextKey.length - textKey.length)
  console.log(excessTextKey, '随机key')
  excessTextKey.forEach((key, index) => {
    headerColumns[textKey.length + index].dataIndex = key
  })
  dataList = dataList.filter(item => !isEmpty(item)).map(i => {
    let excessKeyAndValue: { key: string, value: string, label: string }[] = []
    let excessKeyAndValue1 = {}
    let needOmitKey: string[] = []
    excessTextKey.forEach((key, index) => {
      let title = headerColumns[textKey.length + index].title!
      let value = i[title]
      let dataIndex = headerColumns[textKey.length + index].dataIndex!

      needOmitKey.push(title)
      excessKeyAndValue1[title] = i[title]
      excessKeyAndValue.push({
        label: title,
        value,
        key: dataIndex
      })
      i[dataIndex] = i[title]
    })
    if (!isEmpty(excessKeyAndValue1)) {
      i.feildList = excessKeyAndValue1
    }
    return omit(i, ...needOmitKey)
  })
  let status = false
  // 单sheet导入通过判断配置的表头名字来验证是否选错导入表格(为了可以自定义表头导入，只要选择的表头包含了配置的表头既正确)

  const inequalityArr = textKey.filter(key => !headerColumnsTextKey.includes(key))
  if (inequalityArr.length === 0 && textKey.length === headerColumnsTextKey.length) {
    status = true
  }
  let errorAndOriginalDataList: Recordable[] = []
  let tempUnValidateNumber: number[] = []
  let cloneDataList = cloneDeep(dataList)
  cloneDataList.forEach((item, index) => {
    // 是否去掉百分号
    if (props.needRemovePercentKey.length) {
      dataList[index] = removePercentSign(cloneDataList, props.needRemovePercentKey, item, index)
    }
    // 先格式化再验证，不会显示invalid date
    // 是否格式化日期
    if (props.needFormatDataKey.length) {
      dataList[index] = formatDate(props.needFormatDataKey, props.format, item)
    }
    // 是否校验数据
    if (!isEmpty(props.validateKeyAndType)) {
      let { cloneItem } = validateData(props.validateKeyAndType, tempUnValidateNumber, item, index)
      errorAndOriginalDataList.push(cloneItem)
    }

    // 转化日期
    for (const key in item) {
      if (/^[0-9]{1,2}\/[0-9]{1,2}(\/[0-9]{1,2})?$/.test(item[key])) {
        item[key] = formatToDate(item[key])
      }
    }

  })
  console.log(unValidateNumber.value, 'unValidateNumber.value', cloneDataList)
  unValidateNumber.value = Array.from(new Set(tempUnValidateNumber)) // 去除重复索引
  // 过滤出没通过校验的数据
  let unValiDateDataList = errorAndOriginalDataList.filter((item, index) => unValidateNumber.value.includes(index))
  //  过滤出通过校验的数据
  let valiDateDataList = cloneDataList.filter((item, index) => !unValidateNumber.value.includes(index))
  console.log(valiDateDataList, '通过校验的数据', unValiDateDataList, '错误的数据')

  // 是否过滤数据
  if (props.isFilter) {
    valiDateDataList = dataFilter(valiDateDataList, props.filterDependedKeyAndString!)
  }
  return {
    headerColumns,
    errorAndOriginalDataList,
    valiDateDataList,
    unValiDateDataList,
    status
  }
}
// 多个sheet导入
function multipleSheets(sheetList: string[][][], sheetNames: string[]) {
  let excelList = [] as IExcelInfo['resultList']
  let status = true
  // 多sheet导入通过判断sheet的名字来验证是否选错导入表格
  if (sheetNames.join(',') !== props.sheetNames.join(',')) {
    status = false
    resetTable()
    return {
      excelList,
      status
    }
  }
  for (let i = 0; i < sheetList.length; i++) {
    if (props.notResolvedSheetIndex.includes(i)) { // 跳过不需要解析的sheet
      continue
    }
    let { headerColumns, dataList, dataSourceList } = getSheetHeaderAndData(sheetList[i], props.textKeyMapArray[i].textKeyMap)
    // 是否去掉百分号
    if (props.textKeyMapArray[i].needRemovePercentKey) {
      let cloneDataList = cloneDeep(dataList)
      cloneDataList.forEach((item, index) => {
        item = removePercentSign(cloneDataList, props.textKeyMapArray[i].needRemovePercentKey!, item, index)
      })
      dataList = cloneDataList
    }
    // 是否过滤数据
    if (props.textKeyMapArray[i].isFilter) {
      dataList = dataFilter(dataList, props.textKeyMapArray[i].filterDependedKeyAndString!)
    }
    excelList.push({
      headerColumns,
      dataList,
      dataSourceList
    })
  }
  return {
    excelList,
    status
  }
}

// 一级菜单映射
// const textKeyMap = {
//   姓名: 'data.realname',
//   证件类型: 'data.mobile', // todo
//   证件号码: 'data.idcard',
//   手机号码: 'data.mobile',
//   性别: 'data.sex',
//   部门: 'data.departmentName',
//   岗位: 'data.jobName',
//   签约公司: 'data.staffContractName',
//   入职日期: 'data.entryDate',
//   合同开始日: 'data.staffStartedAt',
//   合同结束日: 'data.staffEndedAt',
//   试用期开始日: 'data.probationStartAt',
//   试用期结束日: 'data.probationEndAt',
//   开户银行: 'data.bankName',
//   银行支行: 'data.bankBranchInfo',
//   银行账号: 'data.bankCard',
//   开户行省市: 'data.bankCity',
//   户籍地址: 'data.houseAdress',
//   现住址: 'data.currentLiveAddress',
//   紧急联系人: 'data.urgentContacts',
//   紧急联系人电话: 'data.urgentContactsPhone',
//   是否购买社保: 'data.isBuySocial',
//   是否购买公积金: 'data.isBuyProvident',
//   购买社保时间: 'data.socialAddMonth',
//   购买公积金时间: 'data.providentAddMonth',
//   工作地: 'data.workCityName',
//   学历: 'data.education',
//   电子签: 'data.isElectronicSignature',
//   备注: 'data.remark',
//   婚育情况: 'data.marry',
//   用工类型: 'data.staffType',
// }


// 二级菜单映射
// const textKeyMap = {
//   '*姓名': 'user.realname',
//   '*手机号': 'user.mobile',
//   '*身份证号': 'user.idcard',
//   '*员工状态': 'employee.status',
//   '*员工类型': 'employee.type',
//   '*学历': 'employee.education',
//   '*应聘职位': 'employee.jobName',
//   '*性别': 'employee.sex',
//   '*户籍地址': 'employee.nativeAddress',
//   '*期望薪资（元/月）': 'employee.jobSalary',
//   '*现居住地址': 'employee.presentAddress',
//   '*用工单位': 'employee.companyName',
//   '*身高（cm）': 'employee.height',
//   '紧急联系人.*联系人姓名': 'employee.emergencyName',
//   '紧急联系人.*关系': 'employee.emergencyType',
//   '紧急联系人.*联系人电话': 'employee.emergencyMobile',
//   '紧急联系人.通讯地址': 'employee.emergencyAddress',

//   '主要工作经历1.*公司名称': 'workExperience1.companyName',
//   '主要工作经历1.*开始日期': 'workExperience1.startedAt',
//   '主要工作经历1.*结束日期': 'workExperience1.endedAt',
//   '主要工作经历1.*部门': 'workExperience1.departmentName',
//   '主要工作经历1.*职位': 'workExperience1.jobName',
//   '主要工作经历1.证明人': 'workExperience1.referenceName',
//   '主要工作经历1.电话': 'workExperience1.referenceMobile',

//   '主要工作经历2.公司名称': 'workExperience2.companyName',
//   '主要工作经历2.开始日期': 'workExperience2.startedAt',
//   '主要工作经历2.结束日期': 'workExperience2.endedAt',
//   '主要工作经历2.部门': 'workExperience2.departmentName',
//   '主要工作经历2.职位': 'workExperience2.jobName',
//   '主要工作经历2.证明人': 'workExperience2.referenceName',
//   '主要工作经历2.电话': 'workExperience2.referenceMobile',

//   '主要工作经历3.公司名称': 'workExperience3.companyName',
//   '主要工作经历3.开始日期': 'workExperience3.startedAt',
//   '主要工作经历3.结束日期': 'workExperience3.endedAt',
//   '主要工作经历3.部门': 'workExperience3.departmentName',
//   '主要工作经历3.职位': 'workExperience3.jobName',
//   '主要工作经历3.证明人': 'workExperience3.referenceName',
//   '主要工作经历3.电话': 'workExperience3.referenceMobile',
// }

// 三级菜单中文与数据结构的映射
// const textKeyMap = {
//   所得项目: 'project',
//   '本月（次）情况.收入额计算.收入': 'month.income.income',
//   '本月（次）情况.收入额计算.费用': 'month.income.fee',
//   '本月（次）情况.收入额计算.免税收入': 'month.income.freeIncome',
//   '本月（次）情况.减除费用': 'month.cut',
//   '本月（次）情况.专项扣除.基本养老保险费': 'month.specialCut.forOld',
//   '本月（次）情况.专项扣除.基本医疗保险费': 'month.specialCut.forMedical',
//   减按计税比例: 'rate',
// }

</script>
