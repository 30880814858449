import { ExtractPropTypes, PropType } from 'vue'

export const dynamicFormProps = {
  // 双向数据绑定的该组件的值
  value: {
    type: Array as PropType<Recordable[]>,
    default: undefined,
  },
  validKey: {
    type: String,
    require: true,
  },
  dynamicValidateForm: {
    type: Array as PropType<IForm.Schema[]>,
    default: () => [],
  }

}

export type DynamicFormProps = Partial<ExtractPropTypes<typeof dynamicFormProps>>